import React from 'react'
import features from '../../images/features.svg'
const HeaderFeatures = () => {
    return (
        <div>
            <header className=" bg-bgColor">
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="items-center flex flex-col justify-center">
                        <div className="text-xl sm:text-3xl p-1">BricoBrowser is full of features </div>
                        <p className="text-lg m-3">Check out our list of awesome features and functionalities we developed for your delightment.</p>
                    </div>
                    <div className="flex items-end justify-end w-3/5">
                        <img src={features} />
                    </div>
                </div>
            </header>
        </div>
    )
}
export default HeaderFeatures