/* eslint-disable react/prop-types */
import React from 'react'
const DashboardCard = ({ data }) => {
    return (
        <div className="border-2 shadow-md rounded-md p-3 md:p-4 md:m-4 ">
            <div className="text-xl sm:text-3xl m-2 mb-4">{data ? data.title : null}</div>
            {data.video ?
                <video className="lg:w-2/3 m-auto p-2" autoPlay loop muted>
                    <source src={data.video}/>
                Your browser does not support video tag.
                </video>
                : null}
            <div className=" grid grid-cols-1 items-center">
                <div className="">
                    <div>{data ? data.description : null}</div>
                </div>
              
            </div>
        </div>

    )
}
export default DashboardCard