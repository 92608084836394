import React, { useState, useContext } from 'react'
import { featuresData } from '../data/products'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Card from '../components/featuresComponents/card'
import HeaderFeatures from '../components/featuresComponents/headerFeatures'
import { FeatureContext } from '../components/hooks/context'

const Features = () => {

    //destructruing data from context
    const { activeFeature, setActiveFeature } = useContext(FeatureContext)
    //set data of active feature
    const [ activeData, setActiveData ] = useState(featuresData.find(({title}) => title === activeFeature))
    const activateFeature = (feature) => {
        setActiveFeature(feature.title)
        setActiveData(feature)
    }

    return (
        <Layout>
            <Seo title="Home" description="Home page BricoBrowser" />
            <HeaderFeatures/>
            <div className="grid grid-cols-1 md:grid-cols-3 p-10">    
                <div className="col-span-1">
                    <div className="text-mainColor text-left p-2 m-2 text-xl">Features</div>
                    <div className="text-disabledColor tracking-wide p-2 m-2 text-left mt-9 flex flex-col border-l-2">
                        {featuresData.map((feature,i)=>{
                            return(
                                <>
                                    <button onClick={()=>activateFeature(feature)} className={` ${activeFeature === feature.title ? ' border-l-4 border-mainColor text-mainColor' : 'bg-white'} px-2 py-2 my-2 font-bold cursor-pointer text-left hover:text-gray-600`} key={i}>{i+1}. {feature.title}</button>
                                    <span className="sm:block font-medium text-base md:hidden">
                                        {activeFeature === feature.title && <Card data={activeData} />}
                                    </span>
                                </>
                            )
                        })}
                    </div>
                </div>
                <div className="md:col-span-2 md:block text-base hidden">    
                    <Card data={activeData} />
                </div>
            </div>
        </Layout>
        
    )

}
export default Features